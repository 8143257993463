import React, { ReactNode, useRef } from 'react';
import { BsX } from 'react-icons/bs';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import clsx from 'clsx';
import { tisaSans } from '@/pages/_app.page';

interface ModalProps {
  isOpen: boolean;
  children: any;
  footer?: any;
  close: () => void;
  onClose?: () => void;
  title?: string | ReactNode;
  titlePosition?: 'left' | 'center';
  fullscreen?: boolean;
}

const Modal = (props: ModalProps) => {
  const {
    isOpen,
    onClose = () => {},
    children,
    footer,
    close,
    title,
    titlePosition,
  } = props;
  const closeModalButtonRef = useRef(null);

  const onModalClose = () => {
    onClose();
    close();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onModalClose}
      className={'relative'}
      initialFocus={closeModalButtonRef}
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-lightGray bg-opacity-80 z-[150]" />

      {/* Full-screen container to center the panel */}
      <div
        className={clsx(
          'fixed inset-0 flex h-full w-full overflow-y-auto justify-center items-center z-[200]',
          props.fullscreen ? '' : 'sm:p-2 lg:p-4',
          tisaSans.className,
        )}
      >
        <DialogPanel
          className={clsx(
            `relative bg-white border border-gray overflow-hidden sm:p-4 lg:p-8 text-gray-darker`,
            props.fullscreen
              ? 'flex w-full h-full'
              : 'max-w-[748px] h-fit max-h-[90%] rounded-lg sm:m-2 lg:m-8 overflow-y-auto',
          )}
        >
          {!!title && (
            <DialogTitle
              className={`text-xl font-semibold mr-10 mb-4 min-h-[24px] ${
                titlePosition === 'center' ? 'text-center' : 'text-left'
              }`}
            >
              {title}
            </DialogTitle>
          )}
          {children}
          <button
            aria-label="close modal"
            className="absolute top-2 right-2 flex hover:bg-lightGray rounded p-2"
            onClick={onModalClose}
            ref={closeModalButtonRef}
          >
            <BsX className="h-6 w-6 flex-shrink-0" />
          </button>

          {footer ? <div className="mt-8">{footer}</div> : null}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default Modal;
